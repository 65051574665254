<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.kname" @change="Search('condition')" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-autocomplete
            @select="Search('condition')" 
            v-model="search.product_model_code"
            :fetch-suggestions="QuerySearchModel"
            placeholder="型号code"
            @change="Search('condition')"
          />
          <!-- <el-select v-model="search.product_model_code" clearable @change="Search" placeholder="产品型号">
            <el-option
              v-for="item in modellist"
              :key="item.code"
              :label="item.name+'('+item.model+')'"
              :value="item.code">
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.version_code" @change="Search('condition')" placeholder="版本"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <router-link :to="'/iot/version/solid/update/'+0">
          <el-button type="primary" size="small" >新建</el-button>
        </router-link>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="包名称">
        <template v-slot="scope">
          <span>{{ scope.row.package_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品型号">
        <template v-slot="scope">
          <span>{{ modelcodelist[scope.row.product_model_code] +'('+scope.row.product_model_code+')' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="版本号">
        <template v-slot="scope">
          <span>{{ scope.row.version_code }}</span>
        </template>
      </el-table-column>
	  <el-table-column label="直播类型">
	    <template v-slot="scope">
	      <span>{{ scope.row.live_type }}</span>
	    </template>
	  </el-table-column>
      <el-table-column label="包大小">
        <template v-slot="scope">
          <span>{{ scope.row.package_size }}</span>
        </template>
      </el-table-column>
      <el-table-column label="s3存储key">
        <template v-slot="scope">
          <span>{{ scope.row.key }}</span>
        </template>
      </el-table-column>
      <el-table-column label="上传时间">
        <template v-slot="scope">
          <span>{{ changetime(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="版本包md5信息">
        <template v-slot="scope">
          <span>{{ scope.row.md5 }}</span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="当前版本状态">
        <template v-slot="scope">
          <span>{{ scope.row.status==1?'开发版本':scope.row.status==2?'测试版本':scope.row.status==3?'上线版本': 'recall' }}</span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="版本包更新日志">
        <template v-slot="scope">
          <el-button type="info" size="mini" @click="Look(scope.row.log)">查看</el-button>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" width="250px">
        <template v-slot="scope">
          <router-link style="margin-right:10px" :to="'/iot/version/solid/update/'+scope.row.code">
            <el-button type="primary" size="small">修改</el-button>
          </router-link>
          <el-button type="danger" size="small" @click="deleteSolid(scope.row.code)">删除</el-button>
          <!-- <router-link style="margin-left:10px" :to="'/iot/version/solid/regionstatus/'+scope.row.code">
            <el-button type="info" size="small">区域状态</el-button>
          </router-link> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog
      title="提示"
      v-model="dialogVisible"
      width="30%">
      <p>{{Log}}</p>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        product_model_code: '',
        version_code: '',
      },
      list: [],
      total: 0,
      modellist: [],
      modelcodelist: {},
      Log: {},
      dialogVisible: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    // 型号联想
    QuerySearchModel (queryString,cb) {
      var thinkdata = this.modellist;
      var results = queryString ? thinkdata.filter(this.createStateFilter(queryString)) : thinkdata;
      cb(results)
    },
    createStateFilter(queryString) {
      return (state) => {
        // console.log(state,queryString,state.code.indexOf(queryString))
        return (state.value.indexOf(queryString) === 0);
      };
    },
    changetime(item){
      return util.dateFormat(item)
    },
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Getmodelcode () {
      this.api.ProductModelList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          res.data.data.data.forEach(item => {
            this.modellist.push({value:item.code,code:item.code,name:item.name,model:item.model})
            this.modelcodelist[item.code] = item.name
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Search(item) {
      if (item== 'condition') {
        this.search.tip = true
        this.search.page = 1
        sessionStorage.setItem('search',JSON.stringify(this.search));
      }
      this.api.FirmwareList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deleteSolid(item) {
      this.$confirm('是否删除该固件版本?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.FirmwareDelete({code: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    Look (item) {
      this.Log = JSON.parse(item)
      this.dialogVisible = true
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("search") && JSON.parse(sessionStorage.getItem("search")).tip){
          this.search = JSON.parse(sessionStorage.getItem("search"))
        }
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    }
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () {
    this.Getmodelcode()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
